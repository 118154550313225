.user-agreement {
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  color: #333;
  position: relative;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.back-button:hover {
  color: #6200ea;
}

h1, h2 {
  color: #444;
}

p {
  margin-bottom: 20px;
  line-height: 1.6;
}
