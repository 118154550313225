.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  text-align: center;
  padding: 10px;
}

.footer-link {
  color: #888;
  font-size: 0.9rem;
  text-decoration: none;
}

.footer-link:hover {
  color: #333;
}
  