.prompt-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    margin: 0px;
  }
  
  .prompt-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .prompt-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .prompt-input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 80%;
    max-width: 300px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .prompt-button {
    background-color: #6200ea;
    color: white;
    border: none;
    padding: 15px 30px;
    margin: 10px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    width: 80%;
    max-width: 300px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .prompt-button:hover {
    background-color: #3700b3;
  }
  
  .prompt-button:active {
    background-color: #30009c;
  }
  